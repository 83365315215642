/* ibm-plex-sans-100normal - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('IBM Plex Sans Thin '),
    local('IBM Plex Sans-Thin'),
    url('./files/ibm-plex-sans-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-100.woff') format('woff'); /* Modern Browsers */
}

/* ibm-plex-sans-100italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('IBM Plex Sans Thin italic'),
    local('IBM Plex Sans-Thinitalic'),
    url('./files/ibm-plex-sans-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-100italic.woff') format('woff'); /* Modern Browsers */
}

/* ibm-plex-sans-200normal - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('IBM Plex Sans Extra Light '),
    local('IBM Plex Sans-Extra Light'),
    url('./files/ibm-plex-sans-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-200.woff') format('woff'); /* Modern Browsers */
}

/* ibm-plex-sans-200italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('IBM Plex Sans Extra Light italic'),
    local('IBM Plex Sans-Extra Lightitalic'),
    url('./files/ibm-plex-sans-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-200italic.woff') format('woff'); /* Modern Browsers */
}

/* ibm-plex-sans-300normal - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('IBM Plex Sans Light '),
    local('IBM Plex Sans-Light'),
    url('./files/ibm-plex-sans-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* ibm-plex-sans-300italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('IBM Plex Sans Light italic'),
    local('IBM Plex Sans-Lightitalic'),
    url('./files/ibm-plex-sans-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* ibm-plex-sans-400normal - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('IBM Plex Sans Regular '),
    local('IBM Plex Sans-Regular'),
    url('./files/ibm-plex-sans-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* ibm-plex-sans-400italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('IBM Plex Sans Regular italic'),
    local('IBM Plex Sans-Regularitalic'),
    url('./files/ibm-plex-sans-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* ibm-plex-sans-500normal - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('IBM Plex Sans Medium '),
    local('IBM Plex Sans-Medium'),
    url('./files/ibm-plex-sans-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* ibm-plex-sans-500italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('IBM Plex Sans Medium italic'),
    local('IBM Plex Sans-Mediumitalic'),
    url('./files/ibm-plex-sans-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-500italic.woff') format('woff'); /* Modern Browsers */
}

/* ibm-plex-sans-600normal - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('IBM Plex Sans SemiBold '),
    local('IBM Plex Sans-SemiBold'),
    url('./files/ibm-plex-sans-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* ibm-plex-sans-600italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('IBM Plex Sans SemiBold italic'),
    local('IBM Plex Sans-SemiBolditalic'),
    url('./files/ibm-plex-sans-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-600italic.woff') format('woff'); /* Modern Browsers */
}

/* ibm-plex-sans-700normal - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('IBM Plex Sans Bold '),
    local('IBM Plex Sans-Bold'),
    url('./files/ibm-plex-sans-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* ibm-plex-sans-700italic - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('IBM Plex Sans Bold italic'),
    local('IBM Plex Sans-Bolditalic'),
    url('./files/ibm-plex-sans-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ibm-plex-sans-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

